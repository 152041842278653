<template>
    <transition name="el-zoom-in-top" @after-leave="doDestroy">
        <div v-show="showPopper" class="el-autocomplete-suggestion el-popper"
            :class="{ 'is-loading': !parent.hideLoading && parent.loading }" :style="{ width: dropdownWidth }"
            role="region">
            <recycle-scroller class="el-autocomplete-suggestion__wrap" :buffer="200" :prerender="7" :item-size="40"
                key-field="uid" :items="items">
                <template v-slot="{ item, index }">
                    <div v-if="!parent.hideLoading && parent.loading"><i class="el-icon-loading"></i></div>
                    <slot v-else :item="item" :index="index">
                    </slot>
                </template>
            </recycle-scroller>
        </div>
    </transition>
</template>
<script>
import Popper from 'element-ui/src/utils/vue-popper';
import Emitter from 'element-ui/src/mixins/emitter';
import { Scrollbar } from 'element-ui';

export default {
    components: { ElScrollbar: Scrollbar },
    mixins: [Popper, Emitter],

    componentName: 'BaselineAutocompleteSuggestions',

    data() {
        return {
            parent: this.$parent,
            dropdownWidth: ''
        };
    },

    props: {
        options: {
            default() {
                return {
                    gpuAcceleration: false
                };
            }
        },
        id: String,
        items: {
            type: Array,
            default: () => []
        }
    },

    methods: {
        select(item) {
            this.dispatch('BaselineAutocomplete', 'item-click', item);
        }
    },

    updated() {
        this.$nextTick(_ => {
            this.popperJS && this.updatePopper();
        });
    },

    mounted() {
        this.$parent.popperElm = this.popperElm = this.$el;
        this.referenceElm = this.$parent.$refs.input.$refs.input || this.$parent.$refs.input.$refs.textarea;
        // this.referenceList = this.$el.querySelector('.el-autocomplete-suggestion__list');
        // this.referenceList.setAttribute('role', 'listbox');
        // this.referenceList.setAttribute('id', this.id);
    },

    created() {
        this.$on('visible', (val, inputWidth) => {
            this.dropdownWidth = inputWidth + 50 + 'px';
            this.showPopper = val;
        });
    }
};
</script>
<style scoped>
.el-autocomplete-suggestion {
    margin: 5px 0;
    -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    border-radius: 4px;
    border: 1px solid #E4E7ED;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #FFF
}

.el-autocomplete-suggestion__wrap {
    max-height: 280px;
    padding: 10px 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.el-autocomplete-suggestion__list {
    margin: 0;
    padding: 0
}

.el-autocomplete-suggestion li {
    padding: 0 20px;
    margin: 0;
    line-height: 34px;
    cursor: pointer;
    color: #606266;
    font-size: 14px;
    list-style: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.el-autocomplete-suggestion li.highlighted,
.el-autocomplete-suggestion li:hover {
    background-color: #F5F7FA
}

.el-autocomplete-suggestion li.divider {
    margin-top: 6px;
    border-top: 1px solid #000
}

.el-autocomplete-suggestion li.divider:last-child {
    margin-bottom: -6px
}

.el-autocomplete-suggestion.is-loading li {
    text-align: center;
    height: 100px;
    line-height: 100px;
    font-size: 20px;
    color: #999
}

.el-autocomplete-suggestion.is-loading li::after {
    display: inline-block;
    content: "";
    height: 100%;
    vertical-align: middle
}

.el-autocomplete-suggestion.is-loading li:hover {
    background-color: #FFF
}

.el-autocomplete-suggestion.is-loading .el-icon-loading {
    vertical-align: middle
}

.el-popper .popper__arrow,
.el-popper .popper__arrow::after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid
}

.el-popper .popper__arrow {
    border-width: 6px;
    -webkit-filter: drop-shadow(0 2px 12px rgba(0, 0, 0, .03));
    filter: drop-shadow(0 2px 12px rgba(0, 0, 0, .03))
}

.el-popper .popper__arrow::after {
    content: " ";
    border-width: 6px
}

.el-popper[x-placement^=top] {
    margin-bottom: 12px
}

.el-popper[x-placement^=top] .popper__arrow {
    bottom: -6px;
    left: 50%;
    margin-right: 3px;
    border-top-color: #EBEEF5;
    border-bottom-width: 0
}

.el-popper[x-placement^=top] .popper__arrow::after {
    bottom: 1px;
    margin-left: -6px;
    border-top-color: #FFF;
    border-bottom-width: 0
}

.el-popper[x-placement^=bottom] {
    margin-top: 12px
}

.el-popper[x-placement^=bottom] .popper__arrow {
    top: -6px;
    left: 50%;
    margin-right: 3px;
    border-top-width: 0;
    border-bottom-color: #EBEEF5
}

.el-popper[x-placement^=bottom] .popper__arrow::after {
    top: 1px;
    margin-left: -6px;
    border-top-width: 0;
    border-bottom-color: #FFF
}

.el-popper[x-placement^=right] {
    margin-left: 12px
}

.el-popper[x-placement^=right] .popper__arrow {
    top: 50%;
    left: -6px;
    margin-bottom: 3px;
    border-right-color: #EBEEF5;
    border-left-width: 0
}

.el-popper[x-placement^=right] .popper__arrow::after {
    bottom: -6px;
    left: 1px;
    border-right-color: #FFF;
    border-left-width: 0
}

.el-popper[x-placement^=left] {
    margin-right: 12px
}

.el-popper[x-placement^=left] .popper__arrow {
    top: 50%;
    right: -6px;
    margin-bottom: 3px;
    border-right-width: 0;
    border-left-color: #EBEEF5
}

.el-popper[x-placement^=left] .popper__arrow::after {
    right: 1px;
    bottom: -6px;
    margin-left: -6px;
    border-right-width: 0;
    border-left-color: #FFF
}

.el-zoom-in-top-enter-active,
.el-zoom-in-top-leave-active {
    opacity: 1;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transition: opacity .3s cubic-bezier(.23, 1, .32, 1), -webkit-transform .3s cubic-bezier(.23, 1, .32, 1);
    transition: opacity .3s cubic-bezier(.23, 1, .32, 1), -webkit-transform .3s cubic-bezier(.23, 1, .32, 1);
    transition: transform .3s cubic-bezier(.23, 1, .32, 1), opacity .3s cubic-bezier(.23, 1, .32, 1);
    transition: transform .3s cubic-bezier(.23, 1, .32, 1), opacity .3s cubic-bezier(.23, 1, .32, 1), -webkit-transform .3s cubic-bezier(.23, 1, .32, 1);
    -webkit-transform-origin: center top;
    transform-origin: center top
}

.el-zoom-in-top-enter,
.el-zoom-in-top-leave-active {
    opacity: 0;
    -webkit-transform: scaleY(0);
    transform: scaleY(0)
}
</style>
  